import css from './style.css'; // this is needed even if not used in this file
import localizify from 'localizify';
import en from './messages/en.json';
import fr from './messages/fr.json';
import de from './messages/de.json';
import fi from './messages/fi.json';
import OBWidgetButton from './components/widget_button';
import OBWidgetFrame from './components/widget_frame';
import OBServer from './server/ob_server';
import { ONLINE_BOOKINGS_HASH } from './constants'

class OBWidget {
  constructor(salon, buttonOptions = {}) {
    OBServer.fetchWidgetSettings(salon)
    .then(({data}) => {
      this.init(salon, data.attributes, buttonOptions)
    })
    .catch((error) => {
      this.init(salon, {}, buttonOptions);
    });
  }

  init(salon, widgetSettings, buttonOptions) {
    this.salon = salon;
    this.initLocalization(widgetSettings.locale);
    if (true !== buttonOptions.hide_widget_button) {
      this.addWidgetBtn(widgetSettings.widget_button_color || widgetSettings.brand_color);
    }
    if (buttonOptions.custom_button_id) {
      this.initCustomButtonClick(buttonOptions.custom_button_id);
    }

    this.widgetFrame = this.addWidgetFrame(salon, widgetSettings.enable_branch_selector, buttonOptions.force_slide_over);

    if(window.location.hash === `#${ONLINE_BOOKINGS_HASH}`) {
      this.widgetFrame.show();
    }
    window.onhashchange = this.onHashChange.bind(this);
  }

  initLocalization(locale) {
    let language = locale ? locale.split('-')[0] : 'en';
    localizify
      .add('en', en)
      .add('fr', fr)
      .add('de', de)
      .add('fi', fi)
      .setLocale(language);
  }

  onHashChange() {
    if (location.hash !== `#${ONLINE_BOOKINGS_HASH}`) {
      this.widgetFrame.hide();
    } else {
      this.widgetFrame.show();
    }
  }

  initCustomButtonClick(customButtonId) {
    var element = document.getElementById(customButtonId);
    element.addEventListener("click", () => {
      this.widgetFrame.show();
    });
  }

  addWidgetBtn(widgetColor) {
    this.widgetButton =  new OBWidgetButton(widgetColor, () => {
      this.widgetFrame.show();
    });
    document.body.appendChild(this.widgetButton.html());
  }

  addWidgetFrame(salon, enableBranchSelector, forceSlideOver) {
    const widgetFrame = new OBWidgetFrame(salon, enableBranchSelector, forceSlideOver);
    document.body.appendChild(widgetFrame.html());
    return widgetFrame
  }

}

export default OBWidget
