import { t } from 'localizify';
import cal_icon from '../icons/cal_icon';
import { DEFAULT_WIDGET_COLOR } from '../constants';

class OBWidgetButton {
  constructor(widgetColor, onClick) {
    this.widgetColor = widgetColor || DEFAULT_WIDGET_COLOR;
    this.onButtonClick = onClick;
    window.onscroll = this.onscroll
  }

  html() {
    var element = document.createElement('button');
    var bookNowTxt = t('book_now');
    var bookTxt = t('book');

    element.style.backgroundColor = this.widgetColor;
    element.innerHTML = `${cal_icon} <span class="ob-widget-btn__wide_text">${bookNowTxt}</span>
    <span class="ob-widget-btn__narrow_text">${bookTxt}</span> `;
    element.classList.add('ob-widget-btn', 'wide');
    element.addEventListener("click", this.onButtonClick);
    return element;
  }
  onscroll() {
    var btn = document.querySelector('.ob-widget-btn');
    if(!btn.classList.contains('narrow')) {
      btn.classList.add('narrow');
    }
  }
}

export default OBWidgetButton;
