import { t } from 'localizify';

class OBWidgetHeader {
  constructor(back, close) {
    this.back = back;
    this.close = close;
    this.headerItem = null;
    this.addTitleEventListener();
  }

  html() {
    return this.header();
  }

  header() {
    var header = document.createElement('div');
    header.classList.add('ob-widget-container__header')
    header.appendChild(this.backButton());
    header.appendChild(this.headerText());
    header.appendChild(this.closeButton());
    return header;
  }

  addTitleEventListener() {
    window.addEventListener('message', message => {
      if (message) {
        try {
          var json = JSON.parse(message.data);
          if (json.type === 'title') {
            this.setTitle(json.info ) ;
          }
        }catch (error) {}
        
      }
    });
  }

  headerText() {
    var header = document.createElement('div');
    header.classList.add('ob-widget-container__header__title');

    this.headerItem = document.createElement('span');
    this.headerItem.classList.add('ob-widget-container__header__title__text');
    this.setTitle(t('make_booking'));

    header.appendChild(this.headerItem);
   
    return header;
  }

  setTitle(title = t("make_booking")) {
    this.headerItem.innerHTML = title;
  }

  closeButton() {
    var close = document.createElement('div');
    close.classList.add('ob-widget-container__header__close');

    var closeLink = document.createElement('button');
    closeLink.classList.add('ob-widget-container__header__close__cross');
    closeLink.addEventListener("click", this.close);

    close.appendChild(closeLink);
    return close;
  }

  backButton() {
    var back = document.createElement('div');
    back.classList.add('ob-widget-container__header__back');

    var backLink = document.createElement('button');
    backLink.classList.add('ob-widget-container__header__back__arrow');
    backLink.addEventListener("click", this.back);

    back.appendChild(backLink);
    return back;
  }
}

export default OBWidgetHeader;
