import OBWidgetHeader from './widget_header';
import {
  ONLINE_BOOKINGS_HASH,
  ONLINE_BOOKINGS_URL,
  ONLINE_BOOKINGS_BACKEND_URL,
} from '../constants';

export function decorateWithWindowParams(urlString, locationSearch) {
  const url = new URL(urlString);
  // Get the current URL's search parameters
  const searchParams = new URLSearchParams(locationSearch);
  // Append each parameter from the current URL to the iframeURL
  for (const [key, value] of searchParams) {
    url.searchParams.append(key, value);
  }
  // Return the modified url with appended parameters
  return url.toString();
}

class OBWidgetFrame {
  constructor(salon, enableBranchSelector, forceSlideOver = false) {
    this.salon = salon;
    this.enableBranchSelector = enableBranchSelector;
    this.forceSlideOver = forceSlideOver
  }

  getOnlineBookingUrl() {
    var url
    if(this.enableBranchSelector == true && FF_BRANCH_SELECTOR === "true" && !FF_BRANCH_SELECTOR_DISALLOWED_BRANCHES.includes(this.salon)){
      url = `${ONLINE_BOOKINGS_BACKEND_URL}/locations/?subdomain=${this.salon}`;
    } else {
      url = `${ONLINE_BOOKINGS_URL}/${this.salon}`
    }
    return decorateWithWindowParams(url, window.location.search)
  }

  html() {
    var iframe = document.createElement('iframe');
    iframe.setAttribute('src', this.getOnlineBookingUrl());
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('scrolling', 'yes');
    iframe.style.width = '100%';
    iframe.style.height = '100%';

    var container = document.createElement('div');
    container.classList.add('ob-widget-container');

    var frameContainer = document.createElement('div');
    frameContainer.classList.add('ob-widget-container-frame-container');
    frameContainer.appendChild(iframe);

    container.appendChild(frameContainer);

    const widgetHeader = new OBWidgetHeader(this.back, this.hide);
    container.appendChild(widgetHeader.html());

    window.addEventListener('message', message => {
      if (message) {
        try {
          var json = JSON.parse(message.data);
          if (json.type === 'completed') {
            this.hide();
          }
          if (json.type === 'new_ob') {
            this.hideHeader();
          }
        } catch (error) {}
      }
    });

    return container;
  }

  show() {
    const isDesktopUserAgent = !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    if (isDesktopUserAgent || this.forceSlideOver){
      // we show the slide over in case Desktop access or forceful flag
      var container = document.getElementsByClassName("ob-widget-container")[0];
      container.classList.remove('slide-out');
      container.classList.add('slide-in');
      location.hash = ONLINE_BOOKINGS_HASH;
    } else{
      // we navigate to a new blank page on Mobile devices
      window.open(this.getOnlineBookingUrl(), '_blank') 
    }
   
  }

  hide() {
    var container = document.getElementsByClassName("ob-widget-container")[0];
    if(container.classList.contains('slide-in')) {
      container.classList.remove('slide-in');
      container.classList.add('slide-out');
      location.hash = '';

      setTimeout(function(){
        var iframe = document.querySelector('.ob-widget-container-frame-container iframe');
        iframe.src = iframe.src;
      }, 800);
    }
  }

  back(e) {
    var iframe = document.querySelector('.ob-widget-container-frame-container iframe');
    iframe.contentWindow.postMessage(JSON.stringify({type: 'history', info: 'backward'}), '*');
  }

  hideHeader() {
    var header = document.getElementsByClassName("ob-widget-container__header")[0];
    header.style.display = 'none'
  }
}

export default OBWidgetFrame;
