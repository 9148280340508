import 'whatwg-fetch';
import Promise from 'promise-polyfill';
import { ONLINE_BOOKINGS_BACKEND_URL } from '../constants';

class OBServer {
  fetchWidgetSettings(branch) {
    return fetch(`${ONLINE_BOOKINGS_BACKEND_URL}/settings/salons/${branch}`, {
    }).then((response) => response.json());
  }
}

export default new OBServer();
